import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import Image from '@jetshop/ui/Image';

import { Above } from '@jetshop/ui/Breakpoints';
import { BoxContent } from './BoxContent';
import { Link } from 'react-router-dom';
import { theme } from '../../../Theme';

const ImageBoxRowWrapper = styled('div')`
  padding: 0;
  width: 100%;
  max-width: 90rem;
  margin: auto;
  display: flex;
  padding: 0 ${props => props.imageboxpadding}px;
  ${theme.below.xl} {
    padding: 0 1rem;
  }
  ${theme.below.lg} {
    flex-direction: column;
    br {
      display: none;
    }
  }

  &.reverse {
    flex-direction: row-reverse;
    ${theme.below.lg} {
      flex-direction: column;
    }
  }
  &.fullWidth {
    max-width: 100%;
  }

  .image-wrapper {
    --ratio: ;
    max-width: ${props => props.ratio} !important;
    ${theme.below.lg} {
      min-width: 100%;
    }
  }

  .box-wrapper {
    max-width: calc(100% - ${props => props.ratio}) !important;
    ${theme.below.lg} {
      min-width: 100%;
    }
  }

  .text {
    font-family: 'Times New Roman', serif;
    font-size: 16px;
    letter-spacing: 0.25px;
    line-height: 24px;
    margin: 10px 0;
  }
`;

const ImageWrapper = styled('div')`
  width: 100%;
  height: 100%;
  /* min-width: 50%; */
`;
const BoxWrapper = styled('div')`
  position: relative;
  width: 100%;
  background: ${props => props?.backgroundcolor ? props?.backgroundcolor : 'transparent'};
  ${theme.below.lg} {
    .under {
      margin-bottom: 20px;
    }
  }
`;

export const SideBySide = ({
  children,
  padding,
  fullWidth,
  reverse,
  ratio,
  ...rest
}) => {
  return (
    <ImageBoxRowWrapper
      className={cx(
        fullWidth?.value && 'fullWidth',
        reverse?.value && 'reverse',
        'contentComponent'
      )}
      imageboxpadding={padding?.value ?? 0}
      ratio={ratio?.value + '%'}
      textratio={100 - ratio?.value + '%'}
    >
      <ImageBoxItem {...rest} />
    </ImageBoxRowWrapper>
  );
};

export const ImageBoxItem = ({
  background,
  desktopImage,
  mobileImage,
  desktopImageAspect,
  mobileImageAspect,
  verticalPosition,
  horizontalPosition,
  textColor,
  title,
  boldTitle,
  text,
  buttonText,
  buttonTheme,
  link
}) => {
  const imageSizes = [1 / 2, 1 / 2, 1 / 2, 1 / 2];
  return (
    <>
      <ImageWrapper className="image-wrapper">
        <Link to={link?.value}>
          <Above breakpoint="lg">
            {matches => (
              <Image
                sizes={imageSizes}
                src={matches ? desktopImage?.value : mobileImage?.value}
                aspect={
                  matches ? desktopImageAspect?.value : mobileImageAspect?.value
                }
                cover
              />
            )}
          </Above>
        </Link>
      </ImageWrapper>
      <BoxWrapper
        color={textColor?.value}
        backgroundcolor={background?.value}
        className="box-wrapper"
      >
        <Above breakpoint="lg">
          {matches => (
            <BoxContent
              verticalPosition={verticalPosition}
              horizontalPosition={horizontalPosition}
              color={textColor}
              title={title}
              boldTitle={boldTitle}
              text={text}
              buttonText={buttonText}
              buttonTheme={buttonTheme}
              link={link}
              textPosition={matches ? null : { value: 'under' }}
              // hasImage={
              //   desktopImage?.value?.length > 0 ||
              //   mobileImage?.value?.length > 0
              // }
            />
          )}
        </Above>
      </BoxWrapper>
    </>
  );
};
