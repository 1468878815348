import React from 'react';
import { styled } from 'linaria/react';

const RatingWrapper = styled('div')`
  .gmf-product-rating {
    &:not(:empty) + div .empty-rating-stars {
      display: none;
    }

    &:empty {
      display: none;

      & + div .empty-rating-stars {
        display: flex;
      }
    }
  }

  .empty-rating-stars-wrapper {
    width: fit-content;
  }
`;

export const GmfProductRating = ({ productId }) => {
  return (
    <RatingWrapper>
      <div
        className="gmf-product-rating"
        data-compact
        data-product-id={productId}
      ></div>
      <div className="empty-rating-stars-wrapper">
        <EmptyRatingStars />
      </div>
    </RatingWrapper>
  );
};

const starArray = [1, 2, 3, 4, 5];

const Star = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={20}
      height={20}
    >
      <g>
        <path
          fill="#c2c2c2"
          stroke="#c2c2c2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="m12.805 3.983 2.093 4.186c.161.242.403.403.644.483l4.669.644c.725.081.966.966.483 1.449l-3.381 3.301c-.242.161-.322.483-.242.805l.805 4.589c.161.725-.644 1.288-1.288.886l-4.186-2.093c-.242-.161-.564-.161-.805 0l-4.186 2.174c-.644.322-1.369-.242-1.288-.886l.805-4.589c.081-.322-.081-.564-.242-.805l-3.381-3.301c-.483-.483-.242-1.369.483-1.449l4.669-.644c.322-.081.564-.242.644-.483l2.093-4.267c.322-.644 1.288-.644 1.61 0Z"
        />
      </g>
    </svg>
  );
};

const EmptyStarsWrapper = styled('ul')`
  display: flex;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
  height: 20px;
  align-items: center;
  text-decoration: none;

  li {
    text-decoration: none;
  }
  span {
    margin-top: 3px;
    margin-left: 2px;
    font-size: 11px;
    color: #949494;
  }

  svg {
    opacity: 0.6;
  }
`;

const EmptyRatingStars = () => {
  return (
    <EmptyStarsWrapper className="empty-rating-stars">
      {starArray.map(star => (
        <li key={star}>
          <Star />
        </li>
      ))}
      <li>
        <span>(0)</span>
      </li>
    </EmptyStarsWrapper>
  );
};
